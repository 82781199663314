<template>
    <div :class='containerClass' :data-theme='colorScheme' @click='onDocumentClick($event)'>
        <Toast />
        <ConfirmDialog></ConfirmDialog>

        <div class='layout-content-wrapper'>
            <AppTopBar :topbarNotificationMenuActive='topbarNotificationMenuActive'
                       :breadcrumbs='$route["meta"]["breadcrumb"] || {}'
                       :topbarUserMenuActive='topbarUserMenuActive'
                       :layoutMode='layoutMode' :menu='menu' :menuActive='menuActive'
                       :staticMenuMobileActive='staticMenuMobileActive'
                       @menu-click='onMenuClick' @menuitem-click='onMenuItemClick'
                       @root-menuitem-click='onRootMenuItemClick'
                       @menu-button-click='onMenuButtonClick' @search-click='toggleSearch'
                       @topbar-notification='onTopbarNotificationMenuButtonClick'
                       @topbar-user-menu='onTopbarUserMenuButtonClick' @right-menu-click='onRightMenuButtonClick'
                       @right-menubutton-click='onRightMenuButtonClick'></AppTopBar>

            <div class='layout-content'>
                <router-view />
            </div>
            <AppFooter />
        </div>

        <AppRightMenu :rightMenuActive='rightMenuActive' @right-menu-click='onRightMenuClick'></AppRightMenu>

        <AppConfig v-model:configActive='configActive' v-model:layoutMode='layoutMode' v-model:menuTheme='menuTheme'
                   v-model:colorScheme='colorScheme' @config-click='onConfigClick'
                   @config-button-click='onConfigButtonClick'></AppConfig>

        <AppSearch :searchActive='searchActive' @search-click='onSearchClick' @search-hide='onSearchHide' />
    </div>
</template>

<script>
import EventBus from './event-bus';
import AppTopBar from './template/AppTopbar';
import AppFooter from './template/AppFooter';
import AppConfig from './template/AppConfig';
import AppSearch from './template/AppSearch';
import AppRightMenu from './template/AppRightMenu';
import { createNamespacedHelpers, mapGetters } from 'vuex';
const commonStore = createNamespacedHelpers('Common');

export default {
    data() {
        return {
            menuActive: false,
            layoutMode: 'static',
            colorScheme: 'light',
            menuTheme: 'layout-sidebar-cyan',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            searchActive: false,
            searchClick: false,
            userMenuClick: false,
            topbarUserMenuActive: false,
            notificationMenuClick: false,
            topbarNotificationMenuActive: false,
            rightMenuClick: false,
            rightMenuActive: false,
            configActive: false,
            configClick: false,
            menu: [
                {
                    label: 'Home', icon: 'pi pi-fw pi-home',
                    items: [
                        { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/app/home' },
                        { label: 'Chatbots', fa: ['far', 'comment-alt'], visible: () => this.visibleToAdmins,
                            items: [
                                { label: 'Helpdesk', fa: ['fas', 'headset'], to: '/app/chatbots/helpdesk' },
                            ]
                        },
                        { label: 'Stats', fa: ['fas', 'chart-bar'], visible: () => this.visibleToAdmins,
                            items: [
                                { label: 'Signups', fa: ['fas', 'sign-in-alt'], to: '/app/stats/signups/live' },
                                { label: 'Checkouts', fa: ['fas', 'shopping-cart'], to: '/app/stats/checkouts/live' },
                                { label: 'Subscriptions', fa: ['fas', 'dollar-sign'], to: '/app/stats/subs/history' },
                            ]
                        },
                    ],
                },
                { separator: true, visible: () => this.visibleToAdmins },
                {
                    label: 'Utenti', icon: 'pi pi-fw pi-user',
                    visible: () => this.visibleToAdmins,
                    items: [
                        { label: 'Elenco utenti', icon: 'pi pi-fw pi-user-edit', to: '/app/users/users' },
                        { label: 'Accesso CMS', fa: ['fas', 'fingerprint'], to: '/app/users/cms_users' },
                        { label: 'Aziende', fa: ['fas', 'industry'], to: '/app/users/companies' },
                    ],
                },
                { separator: true, visible: () => this.visibleToEditor },
                {
                    label: 'Contenuti', icon: 'pi pi-fw pi-th-large',
                    items: [
                        { label: 'Lookups', icon: 'pi pi-fw pi-tags',
                          items: [
                              { label: 'Categorie', icon: 'pi pi-fw pi-ticket', to: '/app/cont/categories' },
                              { label: 'Tags', icon: 'pi pi-fw pi-tag', to: '/app/cont/tags'},
                              { label: 'Autori', icon: 'pi pi-fw pi-id-card', to: '/app/cont/authors'},
                              { label: 'Publishers', icon: 'pi pi-fw pi-sliders-h', to: '/app/cont/publishers'},
                              { label: 'Copywriters', fa: ['fas', 'feather'], to: '/app/cont/copywriters'},
                              { label: 'Speakers', fa: ['fab', 'teamspeak'], to: '/app/cont/speakers'}
                          ]
                        },
                        { label: 'Curatori', icon: 'pi pi-fw pi-users', to: '/app/cont/experts' },
                        { label: 'Serie di libri', icon: 'pi pi-fw pi-th-large', to: '/app/cont/bookseries' },
                        { label: 'Skills', fa: ['fas', 'award'], to: '/app/cont/skills-v2' },
                        { label: 'Podcast', fa: ['fas', 'podcast'], to: '/app/cont/podcasts' },
                        { label: 'Libri', icon: 'pi pi-fw pi-book', to: '/app/cont/books' },
                        { label: 'Articoli', fa: ['fas', 'pen-nib'], to: '/app/cont/articles' },
                        { label: 'The Updates', fa: ['far', 'newspaper'], to: '/app/cont/the_update' },
                        { label: 'Libri completi', fa: ['fas', 'book-medical'], to: '/app/cont/complete_books' },
                        { label: 'Citazioni', icon: 'pi pi-fw pi-comment', to: '/app/cont/quotes' },
                        { label: 'Quiz', fa: ['fas', 'question'], to: '/app/cont/quiz' },
                        { label: 'Archivio', fa: ['fas', 'archive'],
                            items: [
                                { label: 'Skills', fa: ['fas', 'award'], to: '/app/cont/skills', tags: ['deprecated'] },
                            ]
                        },

                    ],
                    visible: () => this.visibleToEditor,
                },
                { separator: true, visible: () => this.visibleToEditor },
                {
                    label: 'Magazine', icon: 'pi pi-fw pi-map',
                    items: [
                        { label: 'Macrocategorie', icon: 'pi pi-fw pi-sitemap', to: '/app/magazine/macro_categories' },
                        { label: 'Posts', icon: 'pi pi-fw pi-map', to: '/app/magazine/posts' },
                    ],
                    visible: () => this.visibleToEditor,
                },
                { separator: true, visible: () => this.visibleToEditor },
                {
                    label: 'Frontend stuff', icon: 'pi pi-fw pi-globe',
                    items: [
                        { label: 'Offerte', icon: 'pi pi-fw pi-money-bill', to: '/app/web/offers' },
                        {
                            label: 'Abtests',
                            fa: ['fas', 'vials'],
                            to: '/app/analytics/abtests',
                        },
                        {
                            label: 'Redirects',
                            fa: ['fas', 'directions'],
                            to: '/app/web/web_redirects',
                            visible: () => this.visibleToAdmins
                        },
                        { label: 'API doc', icon: 'pi pi-fw pi-cloud', to: '/app/web/apidoc' },
                        /*{ label: 'Vendita Extras', icon: 'pi pi-fw pi-shopping-cart', to: '/app/web/extras' },*/
                    ],
                    visible: () => this.visibleToEditor
                },
                { separator: true, visible: () => this.visibleToEditor },
                {
                    label: 'Tasks', icon: 'pi pi-fw pi-cog',
                    visible: () => {
                        return this.visibleToAdmins
                    },
                    items: [
                        {
                            label: 'Importa Sub B2B',
                            fa: ['fas', 'globe'],
                            to: '/app/tasks/sub_corporate_import',
                        },
                        {
                            label: 'Importa Subscriptions',
                            icon: 'pi pi-fw pi-upload',
                            to: '/app/tasks/import_subscriptions',
                        },
                        { label: 'Importa Acquisti Extras', icon: 'pi pi-fw pi-euro', to: '/app/tasks/import_purchases' },
                    ],
                },
                { separator: true, visible: () => this.visibleToDocs },
                {
                    label: 'Psicologi ',
                    items: [
                        {
                            label: 'Psicologi',
                            fa: ['fas', 'brain'],
                            to: '/app/psycho/psychologists',
                            visible: () => this.visibleToSuperAdmins
                        },
                        {
                            label: 'Statistiche',
                            fa: ['fas', 'chart-bar'],
                            to: '/app/psycho/appointments-stats',
                            visible: () => this.visibleToSuperAdmins
                        },
                        {
                            label: 'Miei pazienti',
                            fa: ['fas', 'user-injured'],
                            to: '/app/psycho/patients',
                            visible: () => this.visibleToDocs
                        },
                    ]
                }
            ],
        };
    },
    computed: {
        ...mapGetters('Authn', ['role', 'authToken']),
        visibleToEditor(){
            return ['admin','super-admin', 'editor'].includes(this.role);
        },
        visibleToAdmins(){
            return ['super-admin', 'admin'].includes(this.role); //all except super-admin
        },
        visibleToSuperAdmins(){
            return ['super-admin'].includes(this.role); //all except super-admin
        },
        visibleToDocs(){
            return ['4mind-doc'].includes(this.role);
        },
        containerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-overlay': this.layoutMode === 'overlay',
                    'layout-static': this.layoutMode === 'static',
                    'layout-slim': this.layoutMode === 'slim',
                    'layout-horizontal': this.layoutMode === 'horizontal',
                    'layout-sidebar-dim': this.colorScheme === 'dim',
                    'layout-sidebar-dark': this.colorScheme === 'dark',
                    'layout-overlay-active': this.overlayMenuActive,
                    'layout-mobile-active': this.staticMenuMobileActive,
                    'layout-static-inactive': this.staticMenuDesktopInactive && this.layoutMode === 'static',
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': !this.$primevue.config.ripple,
                },
                this.colorScheme === 'light' ? this.menuTheme : '',
            ];
        },
    },
    components: {
        AppTopBar,
        AppFooter,
        AppConfig,
        AppSearch,
        AppRightMenu,
    },
    watch: {
        $route(n) {
            this.menuActive = false;
            this.$toast.removeAllGroups();
            this.resetExtraBreadcrumbs();
            console.log("route changed", n)
        },
        role(n){
            console.log("ROLE", n)
        },
        authToken(tok){
            if (tok === ""){
                this.$router.push(('/login'));
            }
        }
    },
    methods: {
        ...commonStore.mapMutations(['resetExtraBreadcrumbs']),
        isMenuVisible(){
            return true;
        },
        onDocumentClick() {
            if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

            if (!this.userMenuClick) {
                this.topbarUserMenuActive = false;
            }

            if (!this.notificationMenuClick) {
                this.topbarNotificationMenuActive = false;
            }

            if (!this.rightMenuClick) {
                this.rightMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isSlim() || this.isHorizontal()) {
                    EventBus.emit('reset-active-index');
                    this.menuActive = false;
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                this.unblockBodyScroll();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.searchClick = false;
            this.configClick = false;
            this.userMenuClick = false;
            this.rightMenuClick = false;
            this.notificationMenuClick = false;
            this.menuClick = false;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;
            this.topbarUserMenuActive = false;
            this.topbarNotificationMenuActive = false;
            this.rightMenuActive = false;

            if (this.isOverlay()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                EventBus.emit('reset-active-index');
                this.hideOverlayMenu();
            }
            if (!event.item.items && (this.isSlim() || this.isHorizontal())) {
                this.menuActive = false;
            }
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onTopbarUserMenuButtonClick(event) {
            this.userMenuClick = true;
            this.topbarUserMenuActive = !this.topbarUserMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        onTopbarNotificationMenuButtonClick(event) {
            this.notificationMenuClick = true;
            this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        toggleSearch() {
            this.searchActive = !this.searchActive;
            this.searchClick = true;
        },
        onSearchClick() {
            this.searchClick = true;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
        onRightMenuClick() {
            this.rightMenuClick = true;
        },
        onRightMenuButtonClick(event) {
            this.rightMenuClick = true;
            this.rightMenuActive = !this.rightMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
            this.unblockBodyScroll();
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                    'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isSlim() {
            return this.layoutMode === 'slim';
        },
        isHorizontal() {
            return this.layoutMode === 'horizontal';
        },
        isOverlay() {
            return this.layoutMode === 'overlay';
        },
        isDesktop() {
            return window.innerWidth > 1091;
        },
    },
};
</script>

<style lang='scss'>
    @import "custom.scss";
</style>
