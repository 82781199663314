<template>
    <div class="layout-topbar">
        <div class="topbar-left">
            <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
                <i class="pi pi-chevron-left"></i>
            </a>

            <router-link to="/" class="horizontal-logo">
                <img id="logo-horizontal" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
            </router-link>

            <span class="topbar-separator"></span>

            <div class="layout-breadcrumb viewname">
                <template v-if="breadcrumbs" >
                    <nav class="p-breadcrumb p-component" aria-label="breadcrumb">
                        <ol class="p-breadcrumb-list">
                            <li class="p-menuitem p-breadcrumb-home">
                                <router-link :to="{path: '/app/home'}" class="p-menuitem-link">
                                    <span class="p-menuitem-icon pi pi-home"></span><!---->
                                </router-link>
                            </li>
                            <template v-for="(bread, index) in breadcrumbs" :key='"page_bc_"+index'>
                                <li class="p-menuitem-separator"><span class="pi pi-chevron-right" aria-hidden="true"></span></li>
                                <li class="p-menuitem">
                                    <router-link  v-if="breadcrumbs[index].to" :to='breadcrumbs[index].to' class="p-menuitem-link"  aria-current="page">
                                        <span class="p-menuitem-text">{{breadcrumbs[index].label}}</span>
                                    </router-link>
                                    <span class="p-menuitem-text" v-else>{{breadcrumbs[index].label}}</span>
                                </li>
                            </template>
                            <template v-if='extraBreadcrumbs'>
                                <template v-for="(bread, index) in extraBreadcrumbs" :key='"page_ebc_"+index'>
                                    <li class="p-menuitem-separator"><span class="pi pi-chevron-right" aria-hidden="true"></span></li>
                                    <li class="p-menuitem">
                                        <router-link  v-if="extraBreadcrumbs[index].to" :to='extraBreadcrumbs[index].to' class="p-menuitem-link"  aria-current="page">
                                            <span class="p-menuitem-text">{{extraBreadcrumbs[index].label}}</span>
                                        </router-link>
                                        <span class="p-menuitem-text" v-else>{{extraBreadcrumbs[index].label}}</span>
                                    </li>
                                </template>
                            </template>
                        </ol>
                    </nav>
                </template>
            </div>

            <img id="logo-mobile" class="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
        </div>

        <AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive"
                 @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>

        <div class="layout-mask modal-in"></div>

        <div class="topbar-right">
            <ul class="topbar-menu">
<!--                <li class="search-item">
                    <a tabindex="0" @click="onSearchClick">
                        <i class="pi pi-search"></i>
                    </a>
                </li>-->
                <li class="notifications-item" :class="{ 'active-menuitem ': topbarNotificationMenuActive }">
                    <a href="#" tabindex="0" @click="onTopbarNotificationMenuButtonClick">
                        <i class="pi pi-bell"></i>
                        <span class="topbar-badge help">1</span>
                    </a>
                    <ul class="notifications-menu fade-in-up">
                        <li role="menuitem">
                            <a href="#" tabindex="0">
                                <i class="pi pi-flag"></i>
                                <div class="notification-item">
                                    <div class="notification-summary">Nuovo CMS</div>
                                    <div class="notification-detail">4books ha appena deployato questo <strong>fantastico</strong> nuovo CMS. Versione corrente {{ version }}</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="profile-item" :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }">
                    <a href="#" @click="onTopbarUserMenuButtonClick">
                        <img :src="photo" alt="diamond-layout" class="profile-image" v-if='photo'/>
                        <span class="profile-name">{{username}}</span>
                    </a>
                    <ul class="profile-menu fade-in-up">
                        <li>
                            <a href="#" @click='onLogout'>
                                <i class="pi pi-power-off"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>

<!--                <li class="right-sidebar-item">
                    <a href="#" tabindex="0" @click="onRightMenuClick">
                        <i class="pi pi-align-right"></i>
                    </a>
                </li>-->
            </ul>
        </div>
    </div>
</template>

<script>
import AppMenu from "./AppMenu";
import { createNamespacedHelpers, mapActions, mapGetters } from 'vuex';
const commonStore = createNamespacedHelpers('Common');

export default {
    name: "AppTopbar",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "menu-button-click", "search-click", "topbar-notification", "topbar-user-menu", "right-menubutton-click"],
    props: {
        topbarNotificationMenuActive: Boolean,
        topbarUserMenuActive: Boolean,
        layoutMode: String,
        menu: Array,
        menuActive: Boolean,
        staticMenuMobileActive: Boolean,
        breadcrumbs: Object
    },
    data() {
        return {
            items: [],
            breadcrumbHome: {icon: 'pi pi-home', to: '/app/dashboard'}
        };
    },
    components: {
        AppMenu
    },
    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        ...mapActions('Authn', ['logout']),
        onMenuClick(event) {
            this.$emit("menu-click", event);
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },
        onRootMenuItemClick(event) {
            this.$emit("root-menuitem-click", event);
        },
        onMenuButtonClick(event) {
            this.$emit("menu-button-click", event);
        },
        onSearchClick(event) {
            this.$emit("search-click", event);
        },
        onTopbarNotificationMenuButtonClick(event) {
            this.$emit("topbar-notification", event);
        },
        onTopbarUserMenuButtonClick(event) {
            this.$emit("topbar-user-menu", event);
        },
        onRightMenuClick(event) {
            this.$emit("right-menubutton-click", event);
        },
        isMobile() {
            return window.innerWidth <= 1091;
        },
        onLogout(){
            this.logout();
            this.$router.push({ name: 'login' });
        }
    },
    computed: {
        ...mapGetters('Authn', ['username', 'photo']),
        ...commonStore.mapGetters(['extraBreadcrumbs']),
        version(){
            return process.env.VUE_APP_VER || "devel";
        },
    },
};
</script>

<style lang='scss'>
#logo-horizontal{
    height: 30px;
    margin-left: -18px;
}
.p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
    margin: 0 0.5rem 0 0.5rem;
    color: #495057;
}
</style>
