<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <img id="footer-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
            <span class="app-name">4BOOKS CMS {{version()}} by gepisolo</span>
        </div>
        <span class="copyright">&#169; 4books.com - 2021</span>
    </div>
</template>

<script>
export default {
    name: "AppFooter",
    methods: {
        version(){
            return process.env.VUE_APP_VER;
        }
    }
};
</script>
